// @ts-strict-ignore
import Link from 'next/link';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { slugify } from 'underscore.string';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { Community, CommunityListPost, ElasticSearchPost, PostType } from 'src/constants/types';
import Avatar, { Size } from 'src/components/avatar';
import CommunityLink from 'src/modules/shared/links/communityLink';
import PostLink from 'src/modules/shared/links/postLink';
import RelativeTime from 'src/components/relativeTime';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import Badge from 'src/components/badge';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';

type Props = {
  community: Community | ElasticSearchPost['community'] | null;
  hideReplies: boolean;
  isSearchPost: boolean;
  isCommunitySearch: boolean;
  onPostClick: (type: string) => void;
  post: CommunityListPost | ElasticSearchPost;
}

export default function PostItemFooter({
  community,
  hideReplies,
  isCommunitySearch,
  isSearchPost,
  onPostClick,
  post,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ context }) => context.currentUser);
  const isVisitorNoRepliesLink = !currentUser && post.totalResponses === 0;

  const onClickUser = (e: SyntheticEvent) => {
    if (!currentUser) e.preventDefault();
    onPostClick('user');
    if (!currentUser) {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'sign-up',
        clickMetadata: {
          element: Element.UserLink,
          section: Section.CommunityHomePost,
        },
      });
      dispatch(showSignupModal());
    }
  };

  const onClickPost = (e: SyntheticEvent) => {
    onPostClick('number-of-replies');
    if (isVisitorNoRepliesLink) {
      if (e) e.preventDefault();
      dispatch(showSignupModal());
    }
  };

  const Replies = () => {
    if ('isLocked' in post && post.isLocked && post.totalResponses === 0) return null;
    return (
      <PostLink
        anchorToResponses={true}
        communitySlug={community?.slug}
        disabled={isVisitorNoRepliesLink}
        postId={post.postId}
        postTypeId={PostType.Post}
        urlEncodedTitle={slugify(post.title)}
        onClick={onClickPost}
      >
        {post.totalResponses > 0
          ? <Text>{post.totalResponses} {post.totalResponses === 1 ? i18n.t('Reply') : i18n.t('Replies')}</Text>
          : <>{'isArchived' in community && !community.isArchived && i18n.t('Be the first to reply')}</>
        }
      </PostLink>
    );
  };

  return (
    <Container>
      <UserLink>
        <Link
          aria-label={`View ${post.author.username} profile`}
          as={`/user/${post.author.username}`}
          href={`/user/profile?username=${post.author.username}`}
          onClick={(e) => onClickUser(e)}
        >
          <Avatar hash={post.author.userImageHash} name={post.author.username} size={Size.Small} />
        </Link>
      </UserLink>
      <div>
        <Row>
          <UserLink>
            <Link
              as={`/user/${post.author.username}`}
              href={`/user/profile?username=${post.author.username}`}
              onClick={onClickUser}
            >
              {post.author.username}
            </Link>
          </UserLink>
          {post.author.roles?.length > 0 && <Badge>{post.author.roles[0]}</Badge>}
          {isSearchPost
            ? !isCommunitySearch && (
              <CommunitySearch>
                {i18n.t('in ')}
                <CommunityLink
                  name={community.name}
                  slug={community.slug}
                  onClick={() => onPostClick('community')}
                />
              </CommunitySearch>
            ) : (
              <>
                <Separator>•</Separator><StyledRelativeTime rawTime={post.dateCreated} />
              </>
            )
          }
        </Row>
        <Row>
          {isSearchPost ? (
            <>
              <StyledRelativeTime rawTime={post.dateCreated} />
              {!hideReplies && <><Separator>•</Separator><Replies /></>}
            </>
          ) : (
            <Replies />
          )}
        </Row>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 15px;
  color: ${({ theme }) => theme.colorGreyDark};
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Separator = styled.span`
  margin-right: 8px;
  margin-left: 8px;
  @media (${props => props.theme.underScreenXsmall}) {
    display: none;
  }
`;
const StyledRelativeTime = styled(RelativeTime)`
  margin-right: 8px;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.colorGreyDark};
`;
const UserLink = styled.div`
  a {
    margin-right: 8px;
    color: ${({ theme }) => theme.colorGreyDark};
    transition: all 0.5s ease;
    &:hover {
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;
const CommunitySearch = styled.span`
  margin-left: 8px;
  a {
    color: ${({ theme }) => theme.colorGreyDark};
  }
`;
