import { ReactNode } from 'react';
import Link from 'next/link';

type CommunityLink = {
  name: string;
  slug: string;
  onClick?: () => void;
  children?: ReactNode;
}

const CommunityLink = ({ name, slug, children, onClick }: CommunityLink) => {
  const linkToCommunity = `/${slug}`;
  const href = `/community/communityHome?communitySlug=${slug}`;

  return (
    <Link
      aria-label={name}
      as={linkToCommunity}
      href={href}
      onClick={onClick}
    >
      {children || name}
    </Link>
  );
};

export default CommunityLink;
