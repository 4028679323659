// @ts-strict-ignore
import { format } from 'date-fns/format';
import { formatDistanceStrict } from 'date-fns/formatDistanceStrict';
import { es, pt } from 'date-fns/locale';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';

const locales = { es, pt };

type Props = {
  className?: string;
  rawTime: string;
}
export default function RelativeTime({ className, rawTime = '' }: Props): JSX.Element {
  const lang = useSelector(({ context }) => context.lang);
  // Aurora returns a UTC date string without the "Zulu time" Z for posts and responses
  // TODO: remove this when we use solaris endpoints
  const UTCTime = rawTime.endsWith('0000000', 27) ? rawTime.replace('0000000', '000Z') : rawTime;

  const now = new Date();
  const then = new Date(UTCTime);

  const getDisplay = () => {
    // if difference is less than 1 minute, just show a few seconds ago
    if ((Number(now) - Number(then)) < 60000) {
      return i18n.t('A few seconds ago');
    } else if (Object.keys(locales).includes(lang)) {
      return formatDistanceStrict(then, now, { addSuffix: true, locale: locales[lang] });
    } else {
      // default for date-fns is en-US and is already imported
      return formatDistanceStrict(then, now, { addSuffix: true });
    }
  };

  return (
    <time
      className={className}
      data-testid="date-time"
      dateTime={UTCTime}
      title={UTCTime ? format(then, 'PPPP p') : '...'}
    >
      {UTCTime ? getDisplay() : '...'}
    </time>
  );
}
