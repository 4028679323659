import React from 'react';
import ContentLoader from 'react-content-loader';

const PostListLoader = () => (
  <div className="results-posts">
    {[...Array(5)].map((_, i) => (
      <div className="results-post" key={`loading-post-${i}`}> {/* eslint-disable-line react/no-array-index-key */}
        <ContentLoader
          aria-label=""
          height={100}
          preserveAspectRatio="xMaxYMax"
          uniqueKey={`loading-svg-${i}`}
          width={500}
        >
          <rect
            height="26"
            rx="4"
            ry="4"
            width="200"
          />
          <rect
            height="20"
            rx="3"
            ry="3"
            width="400"
            y="34"
          />
          <rect
            height="4"
            rx="3"
            ry="3"
            width="200"
            x="50"
            y="72"
          />
          <rect
            height="4"
            rx="3"
            ry="3"
            width="120"
            x="50"
            y="84"
          />
          <circle
            cx="20"
            cy="80"
            r="20"
          />
        </ContentLoader>
      </div>
    ))}
  </div>
);

export default PostListLoader;
